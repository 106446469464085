import React, { FC } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface MetricsCardProps {
  text: string;
  icon: string;
  detail: string;
}

export const MetricsCard: FC<MetricsCardProps> = ({ text, icon, detail }) => {
  return (
    <div className="xvia-metrics-card">
      <FontAwesomeIcon
        className="xvia-metrics-card__icon"
        icon={`fa-solid ${icon}` as IconProp}
      />
      <div>
        <h3 className="xvia-metrics-card__text">{text}</h3>
        <p className="xvia-metrics-card__detail">{detail}</p>
      </div>
    </div>
  );
};
