import { Modal, ModalProps } from "antd";

type PopupRootProps = React.PropsWithChildren<ModalProps>;

const DEFAULT_PROPS = {
  cancelText: "Cancelar",
  okText: "Ok",
};

export const PopupRoot = ({
  children,
  cancelText = DEFAULT_PROPS.cancelText,
  okText = DEFAULT_PROPS.okText,
  ...rest
}: PopupRootProps) => {
  return (
    <Modal {...rest}>
      {children}
    </Modal>
  );
};