import { createContext } from "react";

export interface PopupContextData {
  openPopup: (id: string) => void
  closePopup: (id: string) => void
  closeAllPopups: () => void
  getPopup(id: string): Partial<{ id: string, isOpen: boolean, close: () => void }>
  registerPopup(id: string): void
}

export const PopupContext = createContext<PopupContextData>(null as any)