import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { HomeSearch, HomeSearchProps } from "./home-search";
import { useHistory } from "react-router-dom";
import { Catalog } from "../../apis/search-catalog";
import { getServerData } from "../../util/get-server-data";
import { getRelevant } from "../../apis/relevant-catalog";
import { useWebgate } from "@xvia/webgate-connect";

export const HomeSearchContainer: FC = () => {
  const { accessToken, serverData } = useWebgate();
  const [searchValue, setSearchValue] = useState<string>();
  let history = useHistory();

  const [relevantCatalogList, setRelevantCatalogList] = useState<Catalog[]>(
    serverData?.relevant || getServerData("relevant", null)
  );
  const getServerRelevant = useCallback(() => {
    console.log("2 HomeSearchContainer INICIO");
    getRelevant(accessToken)
      .then((result) => {
        setRelevantCatalogList(result);
        console.log("2 HomeSearchContainer FIM");
      })
      .catch((err) => {
        console.log(err);
        console.log("2 HomeSearchContainer ERRO");
      });
  }, [accessToken]);

  const onClickSearch = useCallback(() => {
    if (!searchValue) return;
    history.push(`search/${searchValue}`);
  }, [searchValue, history]);

  const onClickCategory = useCallback(
    (categorySlug) => {
      history.push(`/${categorySlug}`);
    },
    [history]
  );
  const imageBanner = useMemo(
    () =>
      `https://storage.googleapis.com/catalog-files/banner/${Math.floor(
        Math.random() * (5 - 1 + 1) + 1
      )}.jpg`,
    []
  );

  useEffect(() => {
    if (relevantCatalogList) return;
    getServerRelevant();
  }, [getServerRelevant, relevantCatalogList]);

  const props: HomeSearchProps = {
    onChangeText: setSearchValue,
    onClickSearch,

    links: relevantCatalogList
      ?.map((catalog) => {
        return {
          text: catalog.category,
          href: `list/${catalog.categorySlug}`,
        };
      })
      .filter((value, index, array) => {
        return array.findIndex((t) => t.href === value.href) === index;
      })
      .slice(0, 4), //Quatidade de itens na barra
    onClickCategory,
    imageBanner,
  };

  return <HomeSearch {...props} />;
};
