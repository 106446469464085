import axios from "axios";

const apiUrl = process.env.BACKEND_ENDPOINT || "";

export interface ICategory {
  id: string;
  slug: string;
  name: string;
  description: string;
  hidden: boolean;
  icon: string;
  orderIndex: number;
  __categories__?: string[];
  __has_categories__?: boolean;
}

export async function getCategorys(): Promise<ICategory[]> {
  const response = await axios.get(`${apiUrl}/v1/category`);

  return response.data;
}
