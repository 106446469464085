import React, { FC } from "react";
import { Apps, AppsProps } from "./apps";

export const AppsContainer: FC = () => {
  const props: AppsProps = {
    apps: [
      {
        name: "MT Cidadão",
        imageUrl:
          "https://play-lh.googleusercontent.com/ZunvUh21a7pJXKa8WWIHjh7d-bzEYJzSXiO3IfS7hDq7nGk3uND1LVcqnPqkuJTl5e4=w240-h480-rw",
        googleLink:
          "https://play.google.com/store/apps/details?id=br.gov.mt.cepromat.mtcidadao&hl=en&gl=US",
        appleLink: "https://apps.apple.com/br/app/mt-cidad%C3%A3o/id1062953749",
      },
      {
        name: "MT Servidor",
        imageUrl:
          "https://is4-ssl.mzstatic.com/image/thumb/Purple112/v4/6c/f8/d0/6cf8d0ae-c6e8-554f-25df-9e8604306bfe/AppIcon-0-0-1x_U007emarketing-0-0-0-10-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/217x0w.webp",
        googleLink:
          "https://play.google.com/store/apps/details?id=br.gov.mt.mtservidor",
        appleLink: "https://apps.apple.com/br/app/mt-servidor/id1548785461",
      },
      {
        name: "MT Empresarial",
        imageUrl:
          "https://play-lh.googleusercontent.com/t01uxCIa-SkerBJ0fUeCFxehZSSWnBIRpfm6MHDZbxz6QjNKJiIo54OLeGvTl1H0OQ0=s180-rw",
        googleLink:
          "https://play.google.com/store/apps/details?id=br.gov.mt.mtempresarial",
        appleLink: "https://apps.apple.com/br/app/mt-empresarial/id1572368145",
      },
    ],
  };

  return <Apps {...props} />;
};
