import axios from "axios";
import { Catalog } from "./search-catalog";

const apiUrl = process.env.BACKEND_ENDPOINT || "";

export async function getRecommendations(
  accessToken?: string
): Promise<Catalog[]> {
  const response = await axios.get(
    `${apiUrl}/api/recommendations/v1/catalog/recommendations`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return response.data;
}
