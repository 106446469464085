import React, { FC } from "react";
import { Col, Row } from "antd";
import { AppCard, AppCardProps } from "../../components/cards/app-card";
import { Container } from "../../components/container/container";
import { BreakPage } from "../../components/break-page/break-page";

export interface AppsProps {
  apps: AppCardProps[];
}

export const Apps: FC<AppsProps> = ({ apps }) => {
  return (
    <div className="xvia-apps">
      <Container className="xvia-apps__container">
        <BreakPage text={"APLICATIVOS"} color={"#7CE957"} />
        <h2>TUDO NA PALMA DA SUA MÃO</h2>
        <div>
          <Row justify="center" gutter={[15, 34]}>
            {apps?.map((app, index) => {
              return (
                <Col
                  key={`${app.name}_${index}`}
                  className="xvia-apps__card"
                  xs={24}
                  sm={11}
                  md={11}
                  lg={6}
                  xl={6}
                  xxl={6}
                >
                  <AppCard
                    name={app.name}
                    imageUrl={app.imageUrl}
                    googleLink={app.googleLink}
                    appleLink={app.appleLink}
                  />
                </Col>
              );
            })}
          </Row>
        </div>
      </Container>
    </div>
  );
};
