import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface LinkListProps {
  title: string;
  linkList: { text: string; link: string }[];
}

export const LinkList: FC<LinkListProps> = ({ title, linkList }) => {
  return (
    <nav className="xvia-link-list" aria-label={title}>
      <span className="xvia-link-list__title">{title}</span>
      <div>
        <ul className={"xvia-link-list__ul"}>
          {linkList.map(({text, link}) => {
            return (
              <li key={link}>
                <a
                  href={link}
                  target={"_blank"}
                  className={"xvia-link-list__text"}
                  rel="noreferrer"
                  aria-label={`Ir para ${text}`}
                >
                  <FontAwesomeIcon
                    className={"xvia-link-list__icon"}
                    icon={`fa-solid fa-angle-right` as IconProp}
                  />
                  {text}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </nav>
  );
};
