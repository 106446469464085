import React, { FC, useEffect } from "react";
import { FooterContainer } from "../modules/footer/footer.container";
import { ListContainer } from "../modules/list/list.container";

export const List: FC = () => {
  useEffect(() => {
    if (typeof window !== "undefined" && window.scrollY !== 0) {
      window.scrollTo(0, 0);
    }
  }, []);
  return (
    <>
      <ListContainer />
      <FooterContainer />
    </>
  );
};
