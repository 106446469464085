import React, { FC } from "react";
import { FooterContainer } from "../modules/footer/footer.container";
import { DepartmentContainer } from "../modules/department/department.container";

export const Department: FC = () => {
  return (
    <div>
      <DepartmentContainer />
      <FooterContainer />
    </div>
  );
};
