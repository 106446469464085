import axios from "axios";

const apiUrl = process.env.BACKEND_ENDPOINT || "";

export interface Catalog {
  type: string;
  title: string;
  slug: string;
  description: string;
  tags: string[];
  active: boolean;
  isFree: boolean;
  isOnline: boolean;
  isDigital?: boolean;
  category: string;
  targets: string[];
  department: string;
  departmentSections: string[];
  categorySlug: string;
  highlighted: boolean;
  score: number;
  imageUrl: string;
  icon: string;
  link: string;
  resultTotal: number;
}

export async function search(
  accessToken?: string,
  query?: string,
  category?: string
): Promise<Catalog[]> {
  const response = await axios.post(
    `${apiUrl}/v1/search/new_search`,
    {
      query: query,
      groups: ["CATALOG"],
      category: category,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return response.data;
}
export async function searchDepartment(
  accessToken?: string,
  departmentSlug?: string
): Promise<Catalog[]> {
  const response = await axios.post(
    `${apiUrl}/v1/search/department`,
    {
      groups: ["CATALOG"],
      departmentSlug: departmentSlug,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return response.data;
}
