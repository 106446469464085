import React, { FC, MouseEventHandler } from "react";
import classNames from "classnames";

export interface NewsCardProps {
  title: string;
  categoryName: string;
  imageUrl: string;
  date: Date | string;
  onClick: MouseEventHandler;
}

export const NewsCard: FC<NewsCardProps> = ({
  title,
  categoryName,
  date,
  imageUrl,
  onClick,
}) => {
  const containerClassName = classNames({
    "xvia-news-card": true,
  });

  return (
    <button aria-label={`Leia mais sobre ${title}`} onClick={onClick} className={containerClassName}>
      <div
        className="xvia-news-card__thumb"
        style={{
          backgroundImage: `url("${imageUrl}")`,
        }}
      />
      <div className="xvia-news-card__content">
        <p>
          {title}
        </p>
      </div>
    </button>
  );
};
