import React, { FC, MouseEventHandler, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface IconButtonProps {
  onClick?: MouseEventHandler;
  icon: string;
  active?: boolean;
  flat?: boolean;
}

export const IconButton: FC<IconButtonProps> = ({
  icon,
  active,
  flat,
  onClick,
}) => {
  const buttonClassName = classNames({
    "xvia-icon-button": true,
    "xvia-icon-button--flat": flat,
  });

  const type = active ? "solid" : "solid";

  const clickAction = useCallback(
    (event) => {
      event.stopPropagation();
      if (onClick) onClick(event);
    },
    [onClick]
  );

  return (
    <button className={buttonClassName} onClick={clickAction}>
      <FontAwesomeIcon icon={`fa-${type} ${icon}` as IconProp} />
    </button>
  );
};
