import React, { FC } from "react";
import {
  ServiceCard,
  ServiceCardProps,
} from "../../components/cards/service-card";
import { Spin } from "antd";
import { Container } from "../../components/container/container";
import { Carrocel } from "../../components/carousel/carrocel";
import { BreakPage } from "../../components/break-page/break-page";

export interface RelevantServicesProps {
  services?: ServiceCardProps[];
}

export const RelevantServices: FC<RelevantServicesProps> = ({ services }) => {
  return (
    <div className="xvia-relevant-services">
      <Container className="xvia-relevant-services__container">
        <BreakPage text={"SERVIÇOS EM DESTAQUE"} color={"#0661A7"} />
        <Carrocel
          slidesToShow={
            services?.length && services?.length > 4 ? 4 : services?.length
          }
          aria-label="Carrossel de serviços em destaque"
        >
          {!!services &&
            services?.map((service) => {
              return (
                <div>
                  <ServiceCard
                    key={service.title}
                    title={service.title}
                    categoryIcon={service?.categoryIcon}
                    onClickFavourite={service.onClickFavourite}
                    rating={service?.rating}
                    type={service?.type}
                    imageUrl={service?.imageUrl}
                    targets={service?.targets}
                    link={service.link}
                  />
                </div>
              );
            })}
        </Carrocel>
        {!services && (
          <div className={"xvia-relevant-services__loading"}>
            <Spin />
          </div>
        )}
      </Container>
    </div>
  );
};
