import React, { FC, useState } from "react";
import { Col, Empty, Row } from "antd";
import { Container } from "../../components/container/container";
import { IDepartment } from "../../apis/department";
import { SidebarCard } from "../../components/cards/sidebar-card";
import { SearchInput } from "../../components/search-input/search-input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface DepartmentProps {
  departmentList?: IDepartment[];
  onClick: (slug: string) => void;
  onClickSearch: (text?: string) => void;
}

export const DepartmentList: FC<DepartmentProps> = ({
  onClick,
  departmentList,
  onClickSearch,
}) => {
  const [filter, setFilter] = useState<string>();
  const activeDepartmentList = departmentList?.filter(department => department.active);

  return (
    <div className="xvia-list">
      <div className={"xvia-list__head_content"}>
        <div className={"xvia-list__head_info"} />
        <Container className={"xvia-list__head_info_container"}>
          <h2 className={"xvia-list__head_info__title"}>
            Secretarias de Governo
          </h2>
        </Container>
        <div className="xvia-home-search__gradient"></div>
      </div>
      <Container className="xvia-list__container">
        <Row gutter={30}>
          <Col span={24}>
            <SearchInput
              className="xvia-home-search__input"
              placeholder="Filtrar"
              onClickSearch={() => {
                onClickSearch(filter);
              }}
              onChangeText={(value) => {
                onClickSearch(value);
                setFilter(value);
              }}
            />

            <Row>
              <Col span={24}>
                <h3>{activeDepartmentList?.length} Órgãos</h3>
              </Col>
            </Row>
            <Row gutter={[30, 30]}>
              {activeDepartmentList?.map((department, index) => {
                return (
                  <Col
                    key={index}
                    xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}
                  >
                    <SidebarCard>
                      <a
                        href={`/app/catalog/orgao/${department.slug}`}
                        onClick={(e) => {
                          e.preventDefault();
                          onClick(department.slug);
                        }}
                        style={{ cursor: "pointer" }}
                        tabIndex={0}
                        onKeyDown={(e) => {
                          if(e.key === 'Enter') {
                            onClick(department.slug)
                          }
                        }}
                        aria-label={`Acessar informações sobre ${department.shortName}`}
                      >
                        <Row>
                          <Col>
                            <FontAwesomeIcon
                              icon={"fa-building-columns" as IconProp}
                              style={{
                                fontSize: 40,
                                padding: 20,
                                marginRight: 40,
                                color: "#244B9F",
                              }}
                            />
                          </Col>
                          <Col>
                            <h2 style={{ fontWeight: "bold", marginTop: 10 }}>
                              {department.shortName}
                            </h2>
                            <h3>{department.name}</h3>{" "}
                          </Col>
                        </Row>
                      </a>
                    </SidebarCard>
                  </Col>
                );
              })}
            </Row>
          </Col>

          {!departmentList?.length && (
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Empty description={"Nenhuma secretaria encontrada"} />
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};
