import { NewsCardProps } from "../components/cards/news-card";

let Parser = require("rss-parser");
let parser = new Parser({
  requestOptions: {
    rejectUnauthorized: false,
  },
});

const apiUrl = process.env.BACKEND_ENDPOINT || "";

export interface Catalog {
  type: string;
  title: string;
  description: string;
  tags: string[];
  isFree: boolean;
  isOnline: boolean;
  category: string;
  targets: string[];
  department: string;
  departmentSections: string[];
  categorySlug: string;
  highlighted: boolean;
  score: number;
  imageUrl: string;
  icon: string;
}

export interface PopUpRssItem {
  title: string;
  link: string;
  enclosure: { url: string };
}

export async function getNews(): Promise<NewsCardProps[]> {
  let feed = await parser.parseURL(`${apiUrl}/api/rss-api/v1/rss`);
  return feed.items?.slice(0, 8);
}

export async function getPopupData() {
  let feed = await parser.parseURL(`${apiUrl}/api/rss-api/v1/rss`);
  const popupItem = feed.items.find((item: PopUpRssItem) => item.title === "PopUp");
  if (popupItem) {
    return {
      link: popupItem.guid,
      image: popupItem.enclosure.url
    };
  }
  return null;

}
