import { FC, MouseEventHandler, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Title } from "../typography/title";
import { Paragraph } from "../typography/paragraph";
import { Rating } from "../rating/rating";
import { Tag, Tooltip } from "antd";
import { Link } from "react-router-dom";

export interface ServiceCardProps {
  title: string;
  categoryIcon: string;
  rating?: number;
  imageUrl?: string;
  isOnline?: boolean;
  focused?: boolean;
  isFavourite?: boolean;
  type?: "online" | "in-person";
  targets?: string[];
  onClickFavourite?: MouseEventHandler;
  link?: string;
}

export const ServiceCard: FC<ServiceCardProps> = ({
  title,
  focused,
  type,
  link = "#",
  imageUrl,
  categoryIcon,
  rating,
  targets,
}) => {
  const containerClassName = classNames({
    "xvia-service-card": true,
    "xvia-service-card---focused": focused,
  });

  const serviceTypeIcon = useMemo(
    () =>
      type === "online"
        ? "fa-solid fa-globe-americas"
        : "fa-solid fa-location-dot",
    [type]
  ) as IconProp;

  const serviceTypeText = useMemo(
    () => (type === "online" ? "Online" : "Presencial"),
    [type]
  ) as IconProp;

  function CardContent() {
    return (
      <>
        {imageUrl && (
          <div className="xvia-service-card__thumb">
            <img src={imageUrl} alt={`Imagem do serviço ${title}`} />
          </div>
        )}
        <div className="xvia-service-card__content">
          <div className="xvia-service-card__icon">
            <FontAwesomeIcon icon={`fa-solid ${categoryIcon}` as IconProp} />
          </div>
          <div className="xvia-service-card__rating">
            {rating && <Rating value={rating} readOnly={true} />}
          </div>
          <Title className="xvia-service-card__title" level={5}>
            {title}
          </Title>

          <div className="xvia-service-card__bottom">
            <Paragraph className="xvia-service-card__bottom-text">
              {/*{serviceTypeText}*/}
              {targets?.map((target) => {
                return (
                  <Tag
                    className={`xvia-service-card__tag ${target.replace(
                      " ",
                      "-"
                    )}`}
                  >
                    {target}
                  </Tag>
                );
              })}
            </Paragraph>

            <Tooltip title={serviceTypeText}>
              <FontAwesomeIcon
                className="xvia-service-card__bottom-icon"
                icon={serviceTypeIcon}
              />
            </Tooltip>
          </div>
        </div>
      </>
    );
  }

  return (
    <Link to={link} aria-label={title}>
      <div className={containerClassName}>
        <CardContent />
      </div>
    </Link>
  );
};

