export const getDateVerbose = (time: number) => {
  switch (typeof time) {
    case "number":
      break;
    case "string": {
      if (String(time).length <= 19) {
        time = +new Date(time + "-04:00");
      } else {
        time = +new Date(time);
      }
      break;
    }
    case "object":
      //@ts-ignore
      if (time.constructor === Date) {
        //@ts-ignore
        time = time.getTime();
      }
      break;
    default:
      time = +new Date();
  }
  const time_formats = [
    [60, "seconds", 1], // 60
    [120, "1 minuto atrás", "daqui 1 minuto"], // 60*2
    [3600, "minuto", 60], // 60*60, 60
    [7200, "1 hora atrás", "daqui 1 hora"], // 60*60*2
    [86400, "horas", 3600], // 60*60*24, 60*60
    [172800, "Ontem", "Amanhã"], // 60*60*24*2
    [604800, "dias", 86400], // 60*60*24*7, 60*60*24
    [1209600, "Semana anterior", "Próxima semana"], // 60*60*24*7*4*2
    [2419200, "semanas", 604800], // 60*60*24*7*4, 60*60*24*7
    [4838400, "Último mês", "Próximo mês"], // 60*60*24*7*4*2
    [29030400, "meses", 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
    [58060800, "Último ano", "Próximo ano"], // 60*60*24*7*4*12*2
    [2903040000, "anos", 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
    [5806080000, "Last century", "Next century"], // 60*60*24*7*4*12*100*2
    [58060800000, "ceculos", 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
  ];
  let seconds = (+new Date() - time) / 1000,
    token = "atrás",
    list_choice = 1;

  if (seconds === 0) {
    return "Agora mesmo";
  }
  if (seconds < 0) {
    seconds = Math.abs(seconds);
    token = "daqui";
    list_choice = 2;
  }
  let i = 0,
    format;
  while ((format = time_formats[i++])) {
    if (seconds < format[0]) {
      if (typeof format[2] === "string") {
        return format[list_choice];
      } else {
        return Math.floor(seconds / format[2]) + " " + format[1] + " " + token;
      }
    }
  }
  return time;
};
