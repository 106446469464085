import React, { FC, useCallback, useEffect, useState } from "react";
import { RelevantServices, RelevantServicesProps } from "./relevant-services";
import { useWebgate } from "@xvia/webgate-connect";
import { Catalog } from "../../apis/search-catalog";
import { getServerData } from "../../util/get-server-data";
import { getRelevant } from "../../apis/relevant-catalog";

export const RelevantServicesContainer: FC = () => {
  const { accessToken, serverData } = useWebgate();

  const [relevantCatalogList, setRelevantCatalogList] = useState<Catalog[]>(
    serverData?.relevant || getServerData("relevant", null)
  );
  const getServerRelevant = useCallback(() => {
    console.log("3 RelevantServicesContainer INICIO");
    getRelevant(accessToken)
      .then((result) => {
        setRelevantCatalogList(result);
        console.log("3 RelevantServicesContainer FIM");
      })
      .catch((err) => {
        console.log(err);
        console.log("3 RelevantServicesContainer ERRO");
      });
  }, [accessToken]);

  useEffect(() => {
    if (relevantCatalogList) return;
    getServerRelevant()
  }, [getServerRelevant, relevantCatalogList]);

  const relevantServicesProps: RelevantServicesProps = {
    services: relevantCatalogList?.map((catalog) => {
      return {
        title: catalog.title,
        categoryIcon: catalog.icon,
        imageUrl: catalog.imageUrl,
        onClickFavourite: () => { },
        type: catalog.isOnline ? "online" : "in-person",
        targets: catalog?.targets,
        link: `${catalog.categorySlug}/${catalog.slug}`,
      };
    }),
  };

  return <RelevantServices {...relevantServicesProps} />;
};
