import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faMagnifyingGlass,
  faPlus,
  faMinus,
  faStar,
  faHeart,
  faFilePdf,
  faRightToBracket,
  faCheck,
  faCircleChevronRight,
  faCircleChevronLeft,
  faBuildingColumns,
  faList,
  faTableCellsLarge,
  faClock,
  faUser,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";

// import { fas } from "@fortawesome/free-solid-svg-icons";

import {
  faLocationDot as falLocationDot,
  faGlobeAmericas as falGlobeAmericas,
  // faHeart as falHeart,
  faClock as falClock,
  faTags as falTags,
  faPhoneFlip as falPhone,
  faEnvelope as falEnvelope,
  faCheck as falFaCheck,
  faAngleRight as falAngleRight,
  faHashtag,
  // faHeart,
  faCar,
  faPlugCircleBolt,
  faDroplet,
  faUserDoctor,
  faMapPin,
  faFlask,
  faCoins,
  faStore,
  faShieldHalved,
  faBriefcase,
  faPersonSwimming,
  faLeaf,
  faHouse,
  faTruckMedical,
  faUserGraduate,
  faIndustry,
  faBus,
  faMasksTheater,
  faTractor,
  faCamera,
  faHelmetSafety,
  faMessage,
  faFileSignature,
  faGlobe,
  faBarsStaggered,
  faDatabase,
  faCircleInfo as falFaCircleInfo,
  faHeart as falHeart,
} from "@fortawesome/free-solid-svg-icons";

import {
  faInstagram,
  faFacebookF,
  faYoutube,
  faAndroid,
  faApple,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

library.add(
  faMagnifyingGlass,
  faPlus,
  faMinus,
  faStar,
  faHeart,
  falHeart,
  faCar,
  // @ts-ignore
  falLocationDot,
  falGlobeAmericas,
  falClock,
  falTags,
  falPhone,
  falEnvelope,
  falFaCheck,
  faInstagram,
  faFacebookF,
  faYoutube,
  faTwitter,
  falAngleRight,
  faAndroid,
  faApple,
  faPlugCircleBolt,
  faDroplet,
  faUserDoctor,
  faMapPin,
  faFlask,
  faCoins,
  faStore,
  faShieldHalved,
  faBriefcase,
  faPersonSwimming,
  faLeaf,
  faHouse,
  faTruckMedical,
  faHashtag,
  faUserGraduate,
  faIndustry,
  faBus,
  faMasksTheater,
  faTractor,
  faCamera,
  faHelmetSafety,
  faMessage,
  faFileSignature,
  faGlobe,
  faCheck,
  faFilePdf,
  faRightToBracket,
  faBarsStaggered,
  faDatabase,
  faCircleInfo,
  faCircleChevronRight,
  faCircleChevronLeft,
  faBuildingColumns,
  faList,
  faTableCellsLarge,
  faClock,
  faUser,
  falFaCircleInfo
  //All
  // fas,
  // fal
);
