import React, { FC, useCallback, useEffect, useState } from "react";
import { Indicators, IndicatorsProps } from "./indicators";
import { getServerData } from "../../util/get-server-data";
import { useWebgate } from "@xvia/webgate-connect";
import { getDashboard, IDashboard } from "../../apis/dashboard";
import { MetricsCardProps } from "../../components/cards/metrics-card";

export const IndicatorsContainer: FC = () => {
  const { serverData } = useWebgate();
  const [dashboard, setDashboard] = useState<IDashboard[]>(
    serverData?.dashboard || getServerData("dashboard", null)
  );

  const getDashboardObj = useCallback(() => {
    console.log("7 IndicatorsContainer INICIO");
    getDashboard()
      .then((result) => {
        setDashboard(result);
        console.log("7 IndicatorsContainer INICIO");
      })
      .catch((err) => {
        console.log(err);
        console.log("7 IndicatorsContainer ERRO");
      });
  }, []);

  useEffect(() => {
    if (!dashboard) getDashboardObj();
  }, [dashboard, getDashboardObj]);

  const info: any = {
    catalog_count: { icon: "fa-file-signature", detail: "Serviços no Portal" },
    catalog_online_count: { icon: "fa-globe", detail: "Serviços Online" },
  };
  const props: IndicatorsProps = {
    metrics: dashboard
      ?.filter((i) => {
        return info[i.title]?.icon;
      })
      .map((i, index) => {
        return {
          text: `${i.value}`,
          icon: info[i?.title]?.icon,
          detail: info[i?.title]?.detail,
        } as MetricsCardProps;
      }),
  };

  return <Indicators {...props} />;
};
