import React, { FC } from "react";

export interface BreakPageInfo {
  text: string;
  color: string;
}

export const BreakPage: FC<BreakPageInfo> = ({ text, color }) => {
  return (
    <span className={"xvia-break-page"}>
      <div
        className="xvia-break-page__icon"
        style={{ backgroundColor: color }}
        aria-hidden="true"
      ></div>
      <div className="xvia-break-page__text">{text}</div>
      <div className="xvia-break-page__line"></div>
    </span>
  );
};
