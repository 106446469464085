import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useWebgate } from "@xvia/webgate-connect";
import { getServerData } from "../../util/get-server-data";
import { useHistory, useParams } from "react-router-dom";
import { getDepartments, getSection, IDepartment, ISection } from "../../apis/department";
import { Department, DepartmentProps } from "./department";
import { Catalog, searchDepartment } from "../../apis/search-catalog";
import { compareObjectsByProperty } from "../../util/compare-objects-by-property";

export const DepartmentContainer: FC = () => {
  const { serverData } = useWebgate();
  const history = useHistory();
  const { orgao } = useParams<{ orgao: string }>();
  const ssrData =
    serverData?.departmentList || getServerData("departmentList", null);

  const [department, setDepartment] = useState<IDepartment | undefined>(
    ssrData?.find((i: IDepartment) => i.slug === orgao)
  );
  const departmentServicesRef = useRef<HTMLDivElement>();
  const [section, setSection] = useState<ISection[]>([]);
  const [allServicesData, setAllServicesData] = useState<Catalog[]>([]);
  const [searchTextFilter, setSearchTextFilter] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [resultTotal, setResultTotal] = useState<number>(0);
  const ITEMS_PER_PAGE = 10;

  const currentPage = useMemo(() => page + 1, [page]);
  
  const fetchCatalogData = useCallback(async () => {
    try {
      const res = await searchDepartment(undefined, orgao);
      return res || [];
    } catch (err) {
      console.log(err);
      return [];
    }
  }, [orgao]);

  useEffect(() => {
    fetchCatalogData()
      .then((res) => {
        const sortedData = res.sort((a: Catalog, b: Catalog) =>
          a.title.localeCompare(b.title)
        );
        setAllServicesData(sortedData);
        setResultTotal(sortedData.length);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [fetchCatalogData]);

  const onClickCatalog = useCallback(
    (slug, categorySlug) => {
      history.push(`/${categorySlug}/${slug}`);
    },
    [history]
  );

  const onPaginate = useCallback((id: number) => {
    setPage(id);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  useEffect(() => {
    if (!department) {
      getDepartments()
        .then((list) => {
          const department = list?.find((i) => i.slug === orgao);
          setDepartment(department);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [department, orgao]);

  useEffect(() => {
    if (department) {
      getSection(department.id)
        .then((sections) =>
          setSection(sections.sort(compareObjectsByProperty("name")))
        )
        .catch((err) => {
          console.log(err);
        });
    }
  }, [department]);

  const paginatedData = useMemo(() => {
    const start = page * ITEMS_PER_PAGE;
    const end = start + ITEMS_PER_PAGE;
    return allServicesData.slice(start, end);
  }, [allServicesData, page]);

  const props: DepartmentProps = {
    ...department,
    section,
    catalogList: paginatedData,
    onClickCatalog,
    onPaginate,
    currentPage,
    resultTotal,
    departmentServicesRef,
  } as DepartmentProps;

  return <Department {...props} />;
};
