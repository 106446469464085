import React, { FC } from "react";
import Slider, { Settings } from "react-slick";

export interface CarouselProps {
  children: any;
  slidesToShow?: number;
  itemsLength?: number;
}

export const Carrocel: FC<CarouselProps> = ({
  children,
  slidesToShow = 4,
  itemsLength,
}) => {
  var settings = {
    dots: false,
    infinite: itemsLength && itemsLength <= 3 ? false : true,
    speed: 500,
    slidesToShow: slidesToShow || 4,
    slidesToScroll: 1,
    initialSlide: 0,
    accessibility: true,
    autoplay: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: slidesToShow - 1 || 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: slidesToShow - 1 || 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  } as Settings;
  return (
    <Slider {...settings} className={"xvia-carousel"} aria-live="polite">
      {children}
    </Slider>
  );
};
