import React, { FC, KeyboardEvent, MouseEventHandler } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface CategoryCardProps {
  icon: string;
  detail: string;
  onClick?: MouseEventHandler;
  slug?: string;
}

export const CategoryCard: FC<CategoryCardProps> = ({
  icon,
  detail,
  onClick,
}) => {
  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" && onClick) {
      onClick(event as any); 
    }
  };

  return (
    <div className="xvia-category-card" onClick={onClick} onKeyDown={handleKeyDown} tabIndex={onClick? 0: undefined} role={onClick ? "button" : undefined}>
      <FontAwesomeIcon
        className="xvia-category-card__icon"
        icon={`fa-solid ${icon}` as IconProp}
      />
      <div>
        <p className="xvia-metrics-card__detail">{detail}</p>
      </div>
    </div>
  );
};
