import React, { FC } from "react";
import { Col, Empty, Pagination, Radio, Row, Space } from "antd";
import { Container } from "../../components/container/container";
import { ListingCard } from "../../components/cards/listing-card";
import { Catalog } from "../../apis/search-catalog";
import { SidebarCard } from "../../components/cards/sidebar-card";
import { Input } from "../../components/input/input";
import { Button } from "../../components/button/button";
import striptags from "striptags";
import { Paragraph } from "../../components/typography/paragraph";
import { ICategory } from "../../apis/categorys";
import { Options, Select } from "../../components/select/select";
import { IDepartment } from "../../apis/department";
import { Tag } from "../../components/tag/tag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import getSecurityPublic from '../../../src/seguranca-publica.jpg';

const normalizeText = (text: string) =>
  text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

export interface ListProps {
  list?: Catalog[];
  categoryList?: ICategory[];
  departmentList?: IDepartment[];
  onClick: (categorySlug: string, slug: string) => void;
  onClickFavourite: (slug: string) => void;
  onClickOnlineService: (link: string) => void;
  onClickSearch: () => void;
  onClickClear: () => void
  onSetSearchValue: (value: string) => void;
  onCategorySelect: (value: string) => void;
  onDepartmentClick: (value: string) => void;
  onDispositionClick: (value: any) => void;
  onPaginate: (page: any) => void;
  searchTextFilter?: string;
  searchText?: string;
  categoryFilter?: string;
  category?: string;
  categoryTitle?: string;
  orgaoTitle?: string;
  orgaoSlug?: string;
  resultTotal?: number;
  disposition: "line" | "card";
  currentPage?: number;
}

export const List: FC<ListProps> = ({
  list,
  categoryList,
  departmentList,
  category,
  categoryFilter,
  onClick,
  onClickFavourite,
  onClickOnlineService,
  onClickSearch,
  onClickClear,
  onSetSearchValue,
  onCategorySelect,
  onDepartmentClick,
  onDispositionClick,
  onPaginate,
  searchText,
  searchTextFilter,
  categoryTitle,
  orgaoTitle,
  orgaoSlug,
  disposition,
  resultTotal,
  currentPage,
}) => {
  const normalizedCategoryTitle = normalizeText(categoryTitle || "");

  const backgroundImageUrl =
    normalizedCategoryTitle === "seguranca" ||
    normalizedCategoryTitle === "seguranca publica"
      ? `${process.env.PUBLIC_URL}/images/seguranca-publica.jpg`
      : `${process.env.PUBLIC_URL}/images/${orgaoSlug || category || "default-head-bg"}.jpg`;

  return (
    <div className="xvia-list">
      <div className={"xvia-list__head_content"}>
        <div
          className={"xvia-list__head_info"}
          style={{
            backgroundImage: `url(${backgroundImageUrl})`,
          }}
        />
        <Container className={"xvia-list__head_info_container"}>
          <Paragraph className={"xvia-list__head_info__subtitle"}>
            {!!categoryTitle &&
              !searchText &&
              "Mostrando serviços para a categoria:"}
            {!!orgaoTitle && "Mostrando serviços do órgão:"}
            {!!searchText && "Mostrando serviços pela palavra chave:"}
          </Paragraph>
          <h2 className={"xvia-list__head_info__title"}>
            {!!searchText && <div>"{searchText}"</div>}
            {!!categoryTitle && !searchText && <div>{categoryTitle}</div>}
            {!!orgaoTitle && <div>{orgaoTitle}</div>}

            {!!searchText && !!categoryTitle && (
              <Paragraph style={{ fontSize: 20 }}>{categoryTitle}</Paragraph>
            )}
          </h2>
        </Container>
        <div className="xvia-home-search__gradient"></div>
      </div>
      <Container className="xvia-list__container">
        <Row gutter={30}>
          <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}>
            <SidebarCard title={"Filtro"}>
              <Space size={12} direction={"vertical"}>
                <Input
                  title={"Palavra chave"}
                  onTextChange={onSetSearchValue}
                  value={searchTextFilter}
                  onEnterPress={onClickSearch}
                  placeholder="Buscar por palavra-chave"
                />

                <Select
                  onChange={onCategorySelect}
                  value={categoryFilter}
                  items={categoryList?.map((i) => {
                    return {
                      value: i.slug,
                      text: i.name,
                    } as Options;
                  })}
                  placeholder="Filtrar por categoria"
                />
                <Button
                  title={"Buscar"}
                  icon={"fa-search"}
                  onClick={onClickSearch}
                />

                <Button
                    title={"Limpar filtro"}
                    className="clear-filter-button"
                    onClick={onClickClear}
                  />
              </Space>
            </SidebarCard>
            <SidebarCard title={"Por Órgão"}>
              <div>
                {departmentList?.map((department) => {
                  return (
                    <span
                      onClick={() => {
                        onDepartmentClick(department.slug);
                      }}
                      key={department.id}
                    >
                      <Tag href={"#"}>{department.shortName}</Tag>
                    </span>
                  );
                })}
              </div>
            </SidebarCard>
          </Col>
          <Col xs={24} sm={16} md={16} lg={16} xl={16} xxl={16}>
            <Row>
              <Col span={24}>
                <h3>{resultTotal} serviços encontrados</h3>
              </Col>
              <Col span={24} style={{ marginBottom: 40 }}>
                <Radio.Group
                  onChange={(e) => {
                    onDispositionClick(e?.target?.value);
                  }}
                  defaultValue="card"
                  value={disposition}
                >
                  <Radio.Button value="card">
                    <FontAwesomeIcon
                      icon={"fa-table-cells-large" as IconProp}
                    />
                  </Radio.Button>
                  <Radio.Button value="line">
                    <FontAwesomeIcon icon={"fa-list" as IconProp} />
                  </Radio.Button>
                </Radio.Group>
              </Col>
            </Row>
            <Row gutter={[30, 30]}>
              {list?.map((catalog) => {
                return (
                  <Col
                    span={disposition === "line" ? 24 : 12}
                    key={catalog.title}
                  >
                    <ListingCard
                      title={catalog.title}
                      subtitle={striptags(catalog.description)}
                      type={catalog.isOnline ? "online" : "in-person"}
                      onClick={() => {
                        onClick(catalog.categorySlug, catalog.slug);
                      }}
                      onClickFavourite={() => {
                        onClickFavourite(catalog.slug);
                      }}
                      onClickOnlineService={() => {
                        onClickOnlineService(catalog.link);
                      }}
                      disposition={disposition}
                      isDigital={catalog.isDigital}
                    />
                  </Col>
                );
              })}
            </Row>
            {!list?.length && (
              <Empty description={"Nenhum serviço encontrado"} />
            )}
            <Pagination
              defaultCurrent={1}
              current={currentPage}
              total={resultTotal}
              onChange={onPaginate}
              style={{ marginTop: 40 }}
              showSizeChanger={false}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
