import {
  FC,
  createElement,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";

import { useWebgate } from "@xvia/webgate-connect";
import { getServerData } from "../../util/get-server-data";
import { CatalogDetail, getCatalog } from "../../apis/detail-catalog";
import { Catalog, CatalogProps } from "./catalog";
import { useParams } from "react-router-dom";
import { getDateVerbose as getMaskDateVerbose } from "../../util/mask";
import { message } from "antd";
import { BreadcrumbItem } from "../../components/breadcrumb/breadcrumb";

const portalUrl = process.env.PORTAL_URL;
const domain = portalUrl?.replace("https://", "")?.replace("http://", "");

const getDateVerbose = (date?: string) =>
  date && getMaskDateVerbose(new Date(date).getTime());

export const CatalogContainer: FC = () => {
  const { accessToken, portalConnect, serverData } = useWebgate();
  const [catalogDetail, setCatalogDetail] = useState<CatalogDetail>();
  const { catalog } = useParams<{ catalog: string }>();
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);

  const getDetail = useCallback(
    (slug: string) => {
      getCatalog(accessToken, slug)
        .then((catalog) => {
          if (catalog.active === false) {
            throw new Error();
          }

          setCatalogDetail(catalog);
        })
        .catch((e:any) => {
          message.error("Não foi possível carregar as informações do serviço!");
        });
      
    },
    [accessToken]
  );

  const openLink = useCallback(
    (url) => {
      if (!url) {
        return;
      }

      const isPortalAppRegex = new RegExp(`^(https?://.*${domain})?/app/`);
      const isExternalMtGovPageRegex = new RegExp(`^(https?://.*mt.gov.br).*$`);

      if (
        !isPortalAppRegex.test(`${portalUrl}/app/`) &&
        !url.startsWith("/app/")
      ) {
        window.open(url, "_blank");
        return;
      }
      if (isExternalMtGovPageRegex.test(url)) {
        window.open(url, "_blank");
        return;
      }
      portalConnect?.redirectTo(url);
    },
    [portalConnect]
  );

  useLayoutEffect(() => {
    const catalogDetailFromCache =
      serverData?.catalogDetail ?? getServerData("catalogDetail", null);

    if (catalogDetailFromCache?.slug === catalog) {
      setCatalogDetail(catalogDetailFromCache);
      return;
    }

    getDetail(catalog);
  }, [catalog, serverData]);

  useEffect(() => {
    if (!catalogDetail) {
      return;
    }

    const { category } = catalogDetail;

    setBreadcrumbs([
      { name: category.name, path: `/list/${category.slug}` },
      { name: catalogDetail.name },
    ]);

    return () => {
      setBreadcrumbs([]);
    };
  }, [catalogDetail]);

  const props = {
    ...(catalogDetail as CatalogProps),
    updated_at: getDateVerbose(catalogDetail?.updated_at),
    breadcrumbs,
    onPdfClick: () => {
      window.open(`/v1/catalog/${catalogDetail?.slug}/pdf`, "_blank");
    },
    onServiceClick: () => {
      openLink(catalogDetail?.link);
    },
  } as CatalogProps;

  return createElement(Catalog, props);
};
