import React, { FC } from "react";
import classNames from "classnames";

export interface TagProps {
  href: string;
}

export const Tag: FC<TagProps> = ({ href, children }) => {
  const tagClassName = classNames({
    "xvia-tag": true,
  });
  return (
    <a className={tagClassName} href={href}>
      {children}
    </a>
  );
};
