import React, { FC } from "react";
import { Menu } from "antd";
import { Container } from "../../components/container/container";
//@ts-ignore
import { Link } from "react-scroll";

export interface LinkBarProps {
  linkList: LinkListProps[];
};

interface LinkListProps { 
  text: string; 
  link: string; 
};

export const LinkBar: FC<LinkBarProps> = ({ linkList }: LinkBarProps) => {

  const redirectToSchedules = (linkItem: LinkListProps) => { 
    if (linkItem.text === 'Agendamentos') {
      window.open(linkItem.link, '_blank') 
    }
  };

  return (
    <div className="xvia-link-bar">
      <Container>
        <Menu
          mode="horizontal"
          items={linkList?.map((linkItem: LinkListProps) => {
            return {
              key: linkItem.link,
              label: (
                <Link
                  activeClass="active"
                  className="search"
                  to={linkItem.link}
                  spy={true}
                  smooth={true}
                  duration={500}
                  onClick={() => redirectToSchedules(linkItem)}
                >
                  {linkItem.text}
                </Link>
              ),
            };
          })}
        />
      </Container>
    </div>
  );
};
