import React, { DetailedHTMLProps, FC, ReactNode } from "react";
import classNames from "classnames";

export interface ParagraphProps
  extends DetailedHTMLProps<
    React.HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  > {
  className?: string;
  children?: ReactNode;
}

export const Paragraph: FC<ParagraphProps> = ({
  children,
  className,
  ...props
}) => {
  const paragraphClassName = classNames({
    "xvia-paragraph": true,
    [className as string]: !!className,
  });
  return (
    <p className={paragraphClassName} {...props}>
      {children}
    </p>
  );
};
