import React, { FC } from "react";
import { FooterContainer } from "../modules/footer/footer.container";
import { DepartmentListContainer } from "../modules/department-list/department-list.container";

export const DepartmentList: FC = () => {
  return (
    <div>
      <div>
        <DepartmentListContainer />
      </div>
      <div>
        <FooterContainer />
      </div>
    </div>
  );
};
