/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from "react";
//@ts-ignore
import { Element } from "react-scroll";
import { HomeSearchContainer } from "../modules/home-search/home-search.container";
import { RecommendedServicesContainer } from "../modules/recommended-services/recommended-services.container";
import { RelevantServicesContainer } from "../modules/relevant-services/relevant-services.container";
import { NewsContainer } from "../modules/news/news.container";
import { AppsContainer } from "../modules/apps/apps.container";
import { CategoryContainer } from "../modules/category/category.container";
import { LinkBarContainer } from "../modules/link-bar/link-bar.container";
import { FooterContainer } from "../modules/footer/footer.container";
import { IndicatorsContainer } from "../modules/indicators/indicators.container";
import { OmbudsmanContainer } from "../modules/ombudsman/ombudsman.container";
import { TopServicesContainer } from "../modules/top-services/top-services.container";
import { Popup } from "../components/popup";
import usePopup from "../hooks/usePopup";
import moment from "moment";

export const Home: FC = () => {
  const { open, popup } = usePopup("catalog-home");

  useEffect(() => {
    const actualDate = moment().format("YYYY-MM-DD");
    const deadLineDate = moment("16/12/2024").format("YYYY-MM-DD");

    if (actualDate < deadLineDate) {
      open();
    };
  }, []);

  return (
    <>
      <Popup.Root
        className="rematricula-popup"
        open={popup.isOpen} 
        onOk={popup.close}
        onCancel={popup.close}
        footer={null}
        closeIcon={<span>Fechar [x]</span>}>

        <a
          className="rematricula-popup__content" 
          href="javascript:void(0);"
        >
          <img height={580} src={`${process.env.PUBLIC_URL}/images/pop-image.png`} alt="aviso" />
        </a>
      </Popup.Root>


      <Element name="search">
        <HomeSearchContainer />
      </Element>

      <LinkBarContainer />

      <Element name="recommendedServices">
        <RecommendedServicesContainer />
      </Element>

      <Element name="relevantServices">
        <RelevantServicesContainer />
      </Element>

      <Element name="topServices">
        <TopServicesContainer />
      </Element>

      <Element name="newsContainer">
        <NewsContainer />
      </Element>

      <Element name="appsContainer">
        <AppsContainer />
      </Element>

      <Element name="category">
        <CategoryContainer />
      </Element>

      <Element name="ombudsman">
        <OmbudsmanContainer />
      </Element>

      <Element name="indicators">
        <IndicatorsContainer />
      </Element>

      <FooterContainer />
    </>
  );
};
