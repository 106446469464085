import React, { FC, MouseEventHandler, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface ButtonProps {
  title: string;
  type?: "primary" | "secondary";
  className?: string;
  onClick?: MouseEventHandler;
  icon?: string;
  circle?: boolean;
  disabled?: boolean;
}

export const Button: FC<ButtonProps> = ({
  title,
  type,
  className,
  onClick,
  icon,
  circle,
  disabled,
}) => {
  const isPrimary = useMemo(() => !type || type === "primary", [type]);
  const isSecondary = useMemo(() => type === "secondary", [type]);

  const buttonClassName = useMemo(
    () =>
      classNames({
        "xvia-button": true,
        "xvia-button---primary": isPrimary,
        "xvia-button---secondary": isSecondary,
        "xvia-button---circle": circle,
        [className as string]: !!className,
      }),
    [isPrimary, isSecondary, className, circle]
  );
  return (
    <button className={buttonClassName} onClick={onClick} disabled={disabled} tabIndex={0}>
      {icon ? <FontAwesomeIcon icon={icon as IconProp} /> : null}
      {!!title && <span style={{ marginLeft: !!icon ? 8 : 0 }}>{title}</span>}
    </button>
  );
};
