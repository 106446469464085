import React, { FC, MouseEventHandler, useCallback, useMemo } from "react";
import classNames from "classnames";
import { Title } from "../typography/title";
import { Paragraph } from "../typography/paragraph";
import { Rating } from "../rating/rating";
import { Button } from "../button/button";
import { IconButton } from "../icon-button/icon-button";
import { Info } from "../info/info";

export interface ListingCardProps {
  title: string;
  subtitle: string;
  rating?: number;
  focused?: boolean;
  isFavourite?: boolean;
  isDigital?: boolean;
  cost?: string;
  type?: "online" | "in-person";
  disposition?: "line" | "card";
  onClick: MouseEventHandler;
  onClickFavourite: MouseEventHandler;
  onClickOnlineService: MouseEventHandler;
}

export const ListingCard: FC<ListingCardProps> = ({
  title,
  subtitle,
  focused,
  rating,
  type,
  disposition,
  isFavourite,
  isDigital,
  cost,
  onClick,
  onClickFavourite,
  onClickOnlineService,
}) => {
  const containerClassName = classNames({
    "xvia-listing-card": true,
    "xvia-listing-card---focused": focused,
    "xvia-listing-card__line": disposition === "line",
  });

  const serviceTypeIcon = useMemo(
    () => (type === "online" ? "fa-globe-americas" : "fa-location-dot"),
    [type]
  );

  const serviceTypeText = useMemo(
    () => (type === "online" ? "Online" : "Presencial"),
    [type]
  );

  const onlineServiceClickHandler = useCallback<MouseEventHandler>(
    (event) => {
      event.stopPropagation();
      onClickOnlineService(event);
    },
    [onClickOnlineService]
  );

  return (
    <button aria-label={`Serviço ${title}, tipo ${serviceTypeText}, clique para mais detalhes`} onClick={onClick} className={containerClassName}>
      <div className="xvia-listing-card__content">
        <div className="xvia-listing-card__header-buttons">
          <IconButton
            aria-label={isFavourite ? "Remover dos favoritos" : "Adicionar aos favoritos"}
            onClick={onClickFavourite}
            active={isFavourite}
            icon="fa-heart"
          />
        </div>
        <div className="xvia-listing-card__rating">
          {rating ? <Rating value={rating} readOnly={true} /> : null}
        </div>
        <Title className="xvia-listing-card__title" level={5}>
          {title}
        </Title>
        <Paragraph className="xvia-listing-card__subtitle">
          {subtitle}
        </Paragraph>
        <div className="xvia-listing-card__bottom">
          <Paragraph className="xvia-listing-card__info">
            <Info text={serviceTypeText} icon={serviceTypeIcon} />
          </Paragraph>
          {cost ? (
            <Paragraph className="xvia-listing-card__info">
              <Info text={cost} icon="fa-circle-dollar" />
            </Paragraph>
          ) : null}
          {isDigital && (
            <Button
              type="secondary"
              className="xvia-listing-card__bottom-button"
              title="Acessar Serviço Digital"
              onClick={onlineServiceClickHandler}
              aria-label="Acessar Serviço Digital"
            />
          )}
        </div>
      </div>
    </button>
  );
};
