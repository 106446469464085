import { useCallback, useContext, useEffect, useMemo } from "react";
import { PopupContext } from "../context/PopupContext";

type UsePopup = {
  popup: Partial<{
    id: string;
    isOpen: boolean;
    close: () => void;
  }>,
  open: () => void
  close: () => void
  closeAllPopups: () => void
}

/**
 * This hook is used to open and close a popup.
 * @param id The unique id of the popup to open/close.
 * @returns An object containing the popup, open, close, and closeAllPopups functions.
 */
export default function usePopup(id: string): UsePopup {
  const { 
    getPopup, 
    openPopup, 
    closePopup, 
    closeAllPopups,
    registerPopup,
  } = useContext(PopupContext)

  useEffect(() => {
    registerPopup(id)

    return () => {
      closePopup(id)
    }
  }, [])

  const open = useCallback(() => openPopup(id), [openPopup])

  const close = useCallback(() => closePopup(id), [closePopup])

  const popup = useMemo(() => {
    return getPopup(id)
  }, [open, close])

  return { popup, open, close, closeAllPopups }
}