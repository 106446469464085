export function getServerData(name: string, defaultValue?: any): any {
  const serverData =
    typeof window !== "undefined" ? window?.SERVER_DATA : undefined;

  if (!serverData) {
    return defaultValue;
  }

  return serverData[name] || defaultValue;
}
