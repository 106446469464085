import React, { FC } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface AppCardProps {
  name: string;
  imageUrl: string;
  googleLink?: string;
  appleLink?: string;
}

export const AppCard: FC<AppCardProps> = ({
  name,
  imageUrl,
  googleLink,
  appleLink,
}) => {
  return (
    <div
      className={"xvia-app-card"}
      style={{
        backgroundImage: `url("${imageUrl}")`,
      }}
    >
      <div className={"xvia-app-card__content"}>
        <h3 className={"xvia-app-card__title"}>{name}</h3>
        <div>
          <a href={googleLink}>
            <FontAwesomeIcon
              className={"xvia-app-card__icon"}
              icon={`fa-brands fa-android` as IconProp}
            />
            <div>Android</div>
          </a>
        </div>
        <div>
          <a
            href={appleLink}
            style={{ display: !!appleLink ? "initial" : "none" }}
          >
            <FontAwesomeIcon
              className={"xvia-app-card__icon"}
              icon={`fa-brands fa-apple` as IconProp}
            />
            <div>IOs</div>
          </a>
        </div>
      </div>
    </div>
  );
};
