import { useState } from "react";
import { PopupContext } from "../context/PopupContext";

export type Popup = {
  id: string;
  isOpen: boolean;
}

export const PopupProvider = ({ children }: React.PropsWithChildren<any>) => {
  const [popups, setPopups] = useState<Popup[]>([]);

  function checkUniquePopup(id: string) {
    if (popups.some((popup) => popup.id === id)) {
      return true;
    }

    return false;
  }

  function registerPopup(id: string) {
    if (!checkUniquePopup(id)) {
      setPopups([...popups, { id, isOpen: false }]);
    }
  }

  function openPopup(id: string) {
    if (!checkUniquePopup(id)) {
      setPopups([...popups, { id, isOpen: true }]);
    }
  }

  function closePopup(id: string) {
    setPopups(popups.filter((popup) => popup.id !== id));
  }

  function getPopup(id: string): Partial<Popup> & { close: () => void } {
    const popup = popups.find((popup) => popup.id === id);

    return {
      id,
      isOpen: popup?.isOpen,
      close: () => closePopup(id),
    }
  }

  function closeAllPopups() {
    setPopups([]);
  }
  
  return (
    <PopupContext.Provider
      value={{
        openPopup,
        closePopup,
        getPopup,
        closeAllPopups,
        registerPopup,
      }}
    >
      {children}
    </PopupContext.Provider>
  );
};