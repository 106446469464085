import React, { FC, useMemo } from "react";
import { Space } from "antd";
import { Info } from "../info/info";
import { Paragraph } from "../typography/paragraph";

export interface SessionCardProps {
  name?: string;
  address?: string;
  district?: string;
  city?: string;
  state?: string;
  openingTime?: string;
  telephone?: string;
  email?: string;
  mapUrl?: string;
  complement?: string;
}

export const SessionCard: FC<SessionCardProps> = ({
  name,
  address,
  district,
  city,
  state,
  openingTime,
  telephone,
  email,
  mapUrl,
  complement,
}) => {
  const infoText = useMemo(() => {
    const infoTexts = [
      address ?? "",
      complement ?? "",
      district ?? "",
      city ?? "",
      state ?? "",
    ];

    return infoTexts.filter((text) => text.trim().length > 0).join(", ");
  }, [address, complement, district, city, state]);

  return (
    <div className={"xvia-card-section"}>
      <div>
        {!name && (
          <Paragraph>Nenhuma unidade disponível para este servico</Paragraph>
        )}
        {mapUrl && (
          <iframe
            src={mapUrl}
            title={"mapa"}
            className="xvia-card-section__map"
            aria-label="Mapa da unidade"
            loading="lazy"
            sandbox="allow-scripts allow-same-origin"
          />
        )}

        <Space direction="vertical" size={20} style={{ padding: 30 }}>
          {name && <h4>{name}</h4>}
          <Info text={infoText} icon={"fa-location-dot"} isCustomIcon={false} />
          {openingTime && <Info text={openingTime} icon={"fa-clock"} isCustomIcon={false} />}
          {telephone && (
            <Info text={telephone} icon={"fa-phone"} isCustomIcon={true} />
          )}
          {email && <Info text={email} icon={"fa-envelope"} isCustomIcon={false} />}
        </Space>
      </div>
    </div>
  );
};
